export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは9月より%d点上がりました。変化が見られたのはどの項目ですか？<br>\n日毎に秋らしい陽気になり、朝晩の冷え込みを感じるようになりました。1日の気温差が大きいと、体が気温の変化についていけず体調を崩しやすくなるため注意が必要です。11月の注目特集でも<b>感染症予防・対策</b>について紹介しています。ぜひご活用ください。\n\n",
      "down": "生活習慣スコアは9月より%d点下がりました。変化が見られたのはどの項目ですか？<br>\n日毎に秋らしい陽気になり、朝晩の冷え込みを感じるようになりました。1日の気温差が大きいと、体が気温の変化についていけず体調を崩しやすくなるため注意が必要です。11月の注目特集でも<b>感染症予防・対策</b>について紹介しています。ぜひご活用ください。\n\n",
      "equal100": "生活習慣スコアは9月に引き続き%満点%です。素晴らしい！<br>\n日毎に秋らしい陽気になり、朝晩の冷え込みを感じるようになりました。1日の気温差が大きいと、体が気温の変化についていけず体調を崩しやすくなるため注意が必要です。11月の注目特集でも<b>感染症予防・対策</b>について紹介しています。ぜひご活用ください。\n\n",
      "equal": "生活習慣スコアは9月と同じ点数です。<br>\n日毎に秋らしい陽気になり、朝晩の冷え込みを感じるようになりました。1日の気温差が大きいと、体が気温の変化についていけず体調を崩しやすくなるため注意が必要です。11月の注目特集でも<b>感染症予防・対策</b>について紹介しています。ぜひご活用ください。\n\n"
    },
    "steps": {
      "up": "歩数スコアは9月より%d点上がりました。この調子で頑張りましょう！<br>\nウォーキングは歩き方も重要！猫背にならないよう背筋を伸ばし、腕は後ろに引くように振りましょう。歩き方で効果をより期待できます。\n\n",
      "down": "歩数スコアは9月より%d点下がりました。<br>\nウォーキングは歩き方も重要！猫背にならないよう背筋を伸ばし、腕は後ろに引くように振りましょう。歩き方で効果をより期待できます。\n\n",
      "equal": "歩数スコアは9月と同じ点数です。<br>\nウォーキングは歩き方も重要！猫背にならないよう背筋を伸ばし、腕は後ろに引くように振りましょう。歩き方で効果をより期待できます。\n\n"
    },
    "exercise": {
      "up": "運動のスコアは9月より%d点上がりました。素晴らしい！<br>\n秋は、代謝が高まり痩せやすい時期と言われます。外での運動時間が取れない方は、スキマ時間を使って10月からリニューアルした<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>をやってみませんか？\n\n",
      "down": "運動のスコアは9月より%d点下がりました。先月の自分を振り返ってみましょう。<br>\n秋は、代謝が高まり痩せやすい時期と言われます。外での運動時間が取れない方は、スキマ時間を使って10月からリニューアルした<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>をやってみませんか？\n\n",
      "equal": "運動のスコアは9月と同じ点数です。<br>\n秋は、代謝が高まり痩せやすい時期と言われます。外での運動時間が取れない方は、スキマ時間を使って10月からリニューアルした<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>をやってみませんか？\n\n"
    },
    "meal": {
      "up": "食事のスコアは9月より%d点上がりました。<br>\n秋刀魚や鮭など秋は多くの魚が旬を迎えます。魚に含まれる<b>DHA・EPA</b>にはコレステロールや中性脂肪を低下させる働きがあります。缶詰でも手軽に摂取できるのでおすすめです。\n\n",
      "down": "食事のスコアは9月より%d点下がりました。<br>\n秋刀魚や鮭など秋は多くの魚が旬を迎えます。魚に含まれる<b>DHA・EPA</b>にはコレステロールや中性脂肪を低下させる働きがあります。缶詰でも手軽に摂取できるのでおすすめです。\n\n",
      "equal": "食事のスコアは9月と同じ点数です。<br>\n秋刀魚や鮭など秋は多くの魚が旬を迎えます。魚に含まれる<b>DHA・EPA</b>にはコレステロールや中性脂肪を低下させる働きがあります。缶詰でも手軽に摂取できるのでおすすめです。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは9月より%d点上がりました。<br>\n休肝日を設けていますか？肝臓は休む暇なく働き続けています。そんな肝臓に負担をかけすぎないよう<b>週に2日</b>ほど休肝日を設けるのがおすすめ。詳細は<a href=\"https://go.andwell.jp/library/tag/master9\">こちら</a>から。\n\n",
      "down": "飲酒のスコアは9月より%d点下がりました。飲み過ぎには気をつけましょう。<br>\n休肝日を設けていますか？肝臓は休む暇なく働き続けています。そんな肝臓に負担をかけすぎないよう<b>週に2日</b>ほど休肝日を設けるのがおすすめ。詳細は<a href=\"https://go.andwell.jp/library/tag/master9\">こちら</a>から。\n\n",
      "equal": "飲酒のスコアは9月と同じ点数です。<br>\n休肝日を設けていますか？肝臓は休む暇なく働き続けています。そんな肝臓に負担をかけすぎないよう<b>週に2日</b>ほど休肝日を設けるのがおすすめ。詳細は<a href=\"https://go.andwell.jp/library/tag/master9\">こちら</a>から。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは9月より%d点上がりました。<br>\n10〜11月にかけては、湿度が一気に低下する時期です。乾燥は睡眠の質を下げる原因の1つとなります。加湿器をつけたり、室内に濡れたタオルを干すことで適度な湿度を保ちましょう。\n\n",
      "down": "睡眠のスコアは9月より%d点下がりました。<br>\n10〜11月にかけては、湿度が一気に低下する時期です。乾燥は睡眠の質を下げる原因の1つとなります。加湿器をつけたり、室内に濡れたタオルを干すことで適度な湿度を保ちましょう。\n\n",
      "equal": "睡眠のスコアは9月と同じ点数です。<br>\n10〜11月にかけては、湿度が一気に低下する時期です。乾燥は睡眠の質を下げる原因の1つとなります。加湿器をつけたり、室内に濡れたタオルを干すことで適度な湿度を保ちましょう。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは9月より%d点上がりました。<br>\n寒暖差は体にとって大きなストレスとなります。睡眠時間をしっかり確保し、3食のリズムを崩さない<b>❝規則正しい生活❞</b>を心がけ、自律神経を整えることが大切です。\n\n",
      "down": "ストレスのスコアは9月より%d点下がりました。<br>\n寒暖差は体にとって大きなストレスとなります。睡眠時間をしっかり確保し、3食のリズムを崩さない<b>❝規則正しい生活❞</b>を心がけ、自律神経を整えることが大切です。\n\n",
      "equal": "ストレスのスコアは9月と同じ点数です。<br>\n寒暖差は体にとって大きなストレスとなります。睡眠時間をしっかり確保し、3食のリズムを崩さない<b>❝規則正しい生活❞</b>を心がけ、自律神経を整えることが大切です。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up %d points since September. Which items have you seen changes in?<br>\nThe weather is becoming more autumnal day by day, and the mornings and evenings are getting colder, but we need to be careful because our bodies can't keep up with the large daily temperature differences and can easily get sick. We'll do a special feature on <b>ways to prevent and combat infectious diseases</b> in November as well, so we hope you find it useful.\n\n",
      "down": "Your lifestyle score has gone down %d points since September.  Which items have you seen changes in?<br>\nThe weather is becoming more autumnal day by day, and the mornings and evenings are getting colder, but we need to be careful because our bodies can't keep up with the large daily temperature differences and can easily get sick. We'll do a special feature on ways to <b>prevent and combat infectious diseases</b> in November as well, so we hope you find it useful.\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in September. Excellent!<br>\nThe weather is becoming more autumnal day by day, and the mornings and evenings are getting colder, but we need to be careful because our bodies can't keep up with the large daily temperature differences and can easily get sick. We'll do a special feature on ways to <b>prevent and combat infectious diseases</b> in November as well, so we hope you find it useful.\n\n",
      "equal": "Your lifestyle score is the same as it was in September.<br>\n\nThe weather is becoming more autumnal day by day, and the mornings and evenings are getting colder, but we need to be careful because our bodies can't keep up with the large daily temperature differences and can easily get sick. We'll do a special feature on <b>ways to prevent and combat infectious diseases</b> in November as well, so we hope you find it useful.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since September. Keep up the good work!<br>\nWalking is also about how you do it! Keep your back straight to avoid hunching over, and swing your arms back. Doing it right will help you get the most out of your walks.\n\n",
      "down": "Your steps score has gone down %d points since September. <br>\nWalking is also about how you do it! Keep your back straight to avoid hunching over, and swing your arms back. Doing it right will help you get the most out of your walks.\n\n",
      "equal": "Your steps score is the same as it was in September. <br>\nWalking is also about how you do it! Keep your back straight to avoid hunching over, and swing your arms back. Doing it right will help you get the most out of your walks.\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since September. Excellent!<br>\nFall is said to be a time of increased metabolism and weight loss. If you can't find time to exercise outside, why not use a spare moment to try <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>? (It had a makeover in October.)\n\n",
      "down": "Your exercise score has gone down %d points since September. Let's take a look back at the last month.<br>\nFall is said to be a time of increased metabolism and weight loss. If you can't find time to exercise outside, why not use a spare moment to try <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>? (It had a makeover in October.)\n\n",
      "equal": "Your exercise score is the same as it was in September. <br>\nFall is said to be a time of increased metabolism and weight loss. If you can't find time to exercise outside, why not use a spare moment to try <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">10minutes-break</a>? (It had a makeover in October.)\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since September.<br>\nFall is the season when Pacific saury, salmon, and a host of other fish are at their best. <b>DHA and EPA</b> contained in fish help lower cholesterol and neutral fats. Canned fish is also an easy way to get them, so we recommend that as well.\n\n",
      "down": "Your food score has gone down %d points since September.<br>\nFall is the season when Pacific saury, salmon, and a host of other fish are at their best. <b>DHA and EPA</b> contained in fish help lower cholesterol and neutral fats. Canned fish is also an easy way to get them, so we recommend that as well.\n\n",
      "equal": "Your food score is the same as it was in September. <br>\nFall is the season when Pacific saury, salmon, and a host of other fish are at their best. <b>DHA and EPA</b> contained in fish help lower cholesterol and neutral fats. Canned fish is also an easy way to get them, so we recommend that as well.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since September.<br>\nDo you have liver rest days? The liver works continuously without a break. To avoid putting too much strain on it, the recommendation is to set aside around <b>two days a week</b> to give it a rest. See <a href=\"https://go.andwell.jp/library/tag/master9\">here</a> for details.\n\n",
      "down": "Your alcohol score has gone down %d points since September. Be careful not to drink too much.<br>\nDo you have liver rest days? The liver works continuously without a break. To avoid putting too much strain on it, the recommendation is to set aside around <b>two days a week</b> to give it a rest. See <a href=\"https://go.andwell.jp/library/tag/master9\">here</a> for details.\n\n",
      "equal": "Your alcohol score is the same as it was in September. <br>\nDo you have liver rest days? The liver works continuously without a break. To avoid putting too much strain on it, the recommendation is to set aside around <b>two days a week</b> to give it a rest. See <a href=\"https://go.andwell.jp/library/tag/master9\">here</a> for details.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since September.<br>\nOctober through November is the time of year when the humidity drops dramatically. Dryness is one of the causes of poor sleep quality. So, keep the humidity at a suitable level through measures like using a humidifier or drying wet towels indoors.\n\n",
      "down": "Your sleep score has gone down %d points since September.<br>\nOctober through November is the time of year when the humidity drops dramatically. Dryness is one of the causes of poor sleep quality. So, keep the humidity at a suitable level through measures like using a humidifier or drying wet towels indoors.\n\n",
      "equal": "Your sleep score is the same as it was in September. <br>\nOctober through November is the time of year when the humidity drops dramatically. Dryness is one of the causes of poor sleep quality. So, keep the humidity at a suitable level through measures like using a humidifier or drying wet towels indoors.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since September.<br>\nBig temperature differences are a major stressor for the body. It's important to regulate your autonomic nervous system by maintaining a <b>\"regular lifestyle.\"</b> This includes making sure you get a good night's sleep and sticking to a regular cycle of three meals a day.\n\n",
      "down": "Your stress score has gone down %d points since September.<br>\nBig temperature differences are a major stressor for the body. It's important to regulate your autonomic nervous system by maintaining a <b>\"regular lifestyle.\"</b> This includes making sure you get a good night's sleep and sticking to a regular cycle of three meals a day.\n\n",
      "equal": "Your stress score is the same as it was in September. <br>\nBig temperature differences are a major stressor for the body. It's important to regulate your autonomic nervous system by maintaining a <b>\"regular lifestyle.\"</b> This includes making sure you get a good night's sleep and sticking to a regular cycle of three meals a day.\n\n"
    }
  }
}